<template>
  <div class="container">
    <img
      class="hidden-sm-and-down"
      style="width: 100%"
      src="../../../assets/mock/us/组 7471.png"
      alt=""
    />
    <img
      class="hidden-md-and-up"
      style="width: 100%"
      src="../../../assets/mock/us/新闻中心.png"
      alt=""
    />
    <div class="container warp">
      <div class="top">
        <h3>新闻动态</h3>
        <span></span>
        <p>News and information</p>
      </div>
      <div class="list">
        <ul>
          <li v-for="item in this.new.code" :key="item.article_id">
            <img @click="getNewTo(item.article_id)" :src="item.src" alt="" />
            <div @click="getNewTo(item.article_id)">
              <h5>{{ item.title }}</h5>
              <p>{{ item.description }}</p>
              <p>
                <span>MORE</span><span>{{ item.isAddTime }}</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-pagination
      class="warp"
      style="text-align: right; margin: 1.25rem auto"
      background
      :page-size="8"
      :hide-on-single-page="true"
      layout="prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getNewsInfo } from "../../../api/home/index";
export default {
  data() {
    return {
      new: [],
      currentPage: 1,
      total: 0,
    };
  },
  created() {
    this.getNew();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getNew();
    },
    getNew() {
      let params = {
        page: this.currentPage,
      };
      getNewsInfo(params).then((res) => {
        console.log(res)
        this.new = res.data;
        this.total = res.data.size;
      });
    },
    getNewTo(id) {
      this.$router.push({path: "/newAbout", query: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .top {
    text-align: center;
    h3 {
      margin-top: 1.25rem;
    }
    span {
      width: 1.25rem;
      height: 3px;
      display: inline-block;
      background-color: #3cb59e;
    }
  }
  .list {
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        width: 48%;
        display: flex;
        padding: 0.875rem;
        box-sizing: border-box;
        margin: 0.625rem;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        div {
          margin-left: 1.25rem;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        h5 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        img {
          width: 10.6875rem;
          height: 5.875rem;
        }
        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 0.75rem;
          overflow: hidden;
          margin: 0.625rem 0;
        }
      }
      @media only screen and (max-width: 600px) {
        /* Styles for extra small devices */
        li {
          width: 100%;
          img {
            width: 7.5rem;
          }
        }
      }

      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 600px) {
        /* Styles for small devices */
        li {
          width: 100%;
        }
      }

      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (min-width: 768px) {
        /* Styles for medium devices */
        li {
          width: 100%;
        }
      }

      /* Large devices (laptops/desktops, 992px and up) */
      @media only screen and (min-width: 992px) {
        /* Styles for large devices */
        li {
          width: 47%;
        }
      }

      /* Extra large devices (large laptops and desktops, 1200px and up) */
      @media only screen and (min-width: 1200px) {
        /* Styles for extra large devices */
      }
    }
  }

  /* Extra small devices (phones, 600px and down) */
}
</style>
